<template>
  <div class="assess-stepone">
    <div class="title">
      <div>衍生工具知识评估</div>
    </div>
    <div v-for="(item, index) in form" :key="item.title">
      <van-cell-group>
        <template #title>
         <div class="question">{{index + 1}}. {{item.title}}</div>
        </template>
        <van-radio-group class="group" v-model="item.choiced[0]">
          <van-radio
            v-for="(it, idx) in item.options"
            :key="it"
            icon-size="24px"
            :name="idx"
          >{{it}}</van-radio>
        </van-radio-group>
      </van-cell-group>
      <div class="err-tip" v-if="item.choiced.length > 0 && item.choiced[0] !== item.right[0]">
        选择答案错误
      </div>
    </div>

    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="next" :disabled="disabledNext">
        {{ isAddAccount ? '下一步' : '下一步（专业投资者评估）'}}
      </van-button>
      <van-button class="btn" @click="before">
        上一步
      </van-button>
    </div>

  </div>
</template>

<script>
import {
  Button, CellGroup, Cell, Field, RadioGroup, Radio,
} from 'vant';
import { useStore } from 'vuex';
import {
  onMounted, reactive, ref, watch, computed,
} from 'vue';
import httpApi from '../../utils/httpApi';
import httpAddApi from '../../utils/httpAddApi';

let submitForm;
export default {
  name: 'Home',
  components: {
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },
  data() {
    return {
    };
  },
  methods: {
    onSheetSelect(value) {
      this.isShowSheet = false;
      this.form.value2 = value.name;
    },
    async next() {
      if (this.disabledNext.value) return;
      const httprequest = this.isAddAccount
        ? httpAddApi.updateDerivativeKnowledgeAssessmentChoices : httpApi.postAsessStepOne;
      const { code, msg } = await httprequest(submitForm);
      if (code === 200) {
        if (this.isAddAccount) {
          await this.$api.submitLog({ type: 'submitAddDerivativeKnowledgeAssessment' });
          const addAccountInfo = await httpAddApi.getOpenAccountInfo();
          if (!addAccountInfo) return;
          if (addAccountInfo.major_invester === '是') {
            if (addAccountInfo.add_account_type === addAccountInfo.account_type) {
              this.$router.push({ path: '/riskDisclosure/step_two' });
            } else {
              this.$router.push({ path: '/riskDisclosure/step_one' });
            }
          } else {
            this.$router.push({ path: '/majorOption/step_one' });
          }
        } else {
          this.$router.push({ path: '/majorOption/step_one' });
        }
      } else {
        this.$toast(msg);
      }
    },
    before() {
      this.$router.go(-1);
    },
  },
  setup() {
    const form = reactive([]);
    const store = useStore();
    const isAddAccount = computed(() => store.state.user.isAddAccount);
    onMounted(async () => {
      store.commit('setTitleValue', '衍生产品');
      if (isAddAccount.value) {
        store.commit('setStepsValue', ['风险认知(1/4)', '提交资料']);
        store.commit('setStepsActive', 0);
      } else {
        store.commit('setStepsValue', ['填写资料', '风险认知(1/4)', '提交资料']);
        store.commit('setStepsActive', 1);
      }
      const httprequest = isAddAccount.value
        ? httpAddApi.getDerivativeKnowledgeAssessmentChoices : httpApi.getAsessStepOne;
      const result = await httprequest();
      if (!result) return;
      result.data.forEach((item, idx) => {
        form[idx] = item;
      });
    });

    const disabledNext = ref(true);
    watch(form, (value) => {
      submitForm = JSON.parse(JSON.stringify(value));
      disabledNext.value = false;
      value.forEach((item) => {
        if (item.choiced.length === 0) {
          disabledNext.value = true;
        }
        if (item.choiced[0] !== item.right[0]) {
          disabledNext.value = true;
        }
      });
      httpApi.formLog(submitForm, disabledNext);
    });
    return {
      form,
      isAddAccount,
      disabledNext,
    };
  },
};
</script>

<style lang="less">
  .assess-stepone {
    padding-top: @space-2;
    .title {
      text-align: left;
      margin-left: 16px;
      color: @font-color-1;
      font-size: @font-size-3;
    }
    .question {
      text-align: left;
      color: @font-color-0;
    }
    .err-tip {
      color: red;
      text-align: left;
      padding-left: @space-3;
      margin-top: @space-3;
    }
    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }
    .group {
      .van-radio {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: @space-3;
      }
      div:first-child {
        border-top: none;
      }
    }
  }
</style>
